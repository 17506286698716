import React, { useEffect } from 'react';
import {
    Button,
    Card,
    Form,
    Divider,
    Tabs,
    Spin,
    Space,
    Select,
    Row,
    Col
} from 'antd';
import { useQuery, useLazyQuery } from '@apollo/client';
import { GET_STATIONS, GET_ROUTES_BASE, GET_LABELS, GET_SCENARIOS } from '../../queries';
import RoutesTable from './RoutesTable';
import ScenariosTable from './ScenariosTable';
import LabelTable from './LabelTable';
import { useStickyState } from '../../Utilities/useStickyState';

const RouteManagement = () => {
    const { data: stationsData, loading: loadingStations } = useQuery(GET_STATIONS);
    const [getRoutesData, { data: routesData, refetch: refetchRoutes }] = useLazyQuery(GET_ROUTES_BASE, { fetchPolicy: 'no-cache' });
    const { data: scenariosData, refetch: refetchScenarios } = useQuery(GET_SCENARIOS, { fetchPolicy: 'no-cache' });
    const [getLabelsData, { data: labelsData, refetch: refetchLabels }] = useLazyQuery(GET_LABELS, { fetchPolicy: 'no-cache' });
    const [station, setStation] = useStickyState(null, 'routeManagementStation')
    const [tabName, setTabName] = useStickyState('label', 'routeManagementTabName');
    const [scenarioId, setScenarioId] = useStickyState(null, 'routeManagementScenarioId');
    const [scenarioName, setScenarioName] = useStickyState('', 'routeManagementScenarioName');
    const items = [
        {
            key: 'label',
            label: 'ルート名'
        },
        {
            key: 'route',
            label: '固定ルート'
        }
    ];
    useEffect(() => {
        if (station !== '' && station !== null) {
            getLabelsData({ variables: { stationId: station }});
            getRoutesData({
                variables: {
                    search: `scenario_id=${scenarioId || 1} AND station_id=${station} AND is_visible=true`
                }
            });
        }
    }, [getLabelsData, getRoutesData, station, scenarioId])

    const [form] = Form.useForm();

    if (loadingStations) {
        return <Spin/>;
    }

    return <>
        <Space direction='vertical' style={{ width: '100%' }}>
            <Card bordered={false}>
                <Form form={form} layout='inline'
                    size='large'
                    onFinish={
                        ({ station }) => {
                            setStation(station);

                            // If station is reset the view should be reset to route tab with no scenario selected
                            setTabName('route');
                            setScenarioId(null);
                            setScenarioName('');
                        }
                    }
                >
                    <Row gutter={[16,8]}>
                        <Col>
                            <Form.Item name='station' label="営業所"
                                initialValue={station}
                                rules={[{ required: true, message: '営業所を選択してください' }]}
                            >
                                <Select
                                    showSearch
                                    placeholder="選択してください"
                                    style={{ width: 200 }}
                                >
                                    {
                                        stationsData?.stations?.edges?.map(
                                            station => (
                                                <Select.Option key={station.node.id} value={station.node.id}>
                                                    {station.node.officialName}
                                                </Select.Option>
                                            )
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item shouldUpdate>
                                {
                                    ()=> 
                                    <Button 
                                        htmlType='submit'
                                        type='primary'>
                                        検索
                                    </Button>
                                }
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Card>
            <Divider style={{ margin: 0, padding: 0 }}/>
            { station && <Row>
                <Col span={24} style={{ padding: '0px 24px', background: '#fff' }}>
                    <Tabs defaultActiveKey={tabName} onChange={setTabName} items={items}/>
                </Col>
                {
                    tabName === 'route' && scenarioId ?
                        <RoutesTable
                            stationId={station}
                            routesData={routesData}
                            refetch={refetchRoutes}
                            stationsData={stationsData}
                            scenarioId={scenarioId}
                            scenarioName={scenarioName}
                            setScenarioId={setScenarioId}
                        /> :
                        tabName === 'route' && !scenarioId ?
                            <ScenariosTable
                                station={station}
                                scenariosData={scenariosData}
                                setScenarioId={setScenarioId}
                                setScenarioName={setScenarioName}
                                refetch={refetchScenarios}
                            /> :
                            <LabelTable labelsData={labelsData} refetch={refetchLabels} selectedStation={station}/>
                }
            </Row>}
        </Space>
    </>
};

export default RouteManagement;
