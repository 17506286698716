import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { DeleteOutlined } from '@ant-design/icons';
import { Popconfirm, Button, message } from 'antd';
import { DELETE_COMPANY } from '../../mutations';
import propTypes from 'prop-types';

const DeleteCompaniesButton = ({ selectedCompanyIds, handleCompleted }) => {
    const [deleteCompanies] = useMutation(DELETE_COMPANY, {
        onCompleted: (data) => handleCompleted(data.deleteCompany.companies),
        onError: () => {
            message.error('会社の削除に失敗しました。');
        }
    });
    const [visible, setVisible] = useState(false);

    return <Popconfirm
        title='選択した会社を削除すると、所属するユーザーと営業所のデータも完全に削除されます。この操作は取り消せません。よろしいですか？'
        onConfirm={() => {
            deleteCompanies({ variables: { input: { ids: selectedCompanyIds } } })
            setVisible(false);
        }}
        open={visible}
        okText={'削除する'}
        onCancel={() => setVisible(false)}
    >
        <Button
            onClick={() => setVisible(true)}
            icon={<DeleteOutlined/>}>
            削除
        </Button>
    </Popconfirm>
};

DeleteCompaniesButton.propTypes = {
    handleCompleted: propTypes.func,
    selectedCompanyIds: propTypes.array
};

export default DeleteCompaniesButton;
