import React, { useState, useEffect } from 'react';
import {
    Input,
    Tag,
    Typography,
    Table,
    Space,
    Row,
    Col,
    message
} from 'antd';
import { toFullWidthKatakana, toHalfWidthKatakana } from '../../Utilities/katakanaWidthConversion';
import ToggleLabel from './ToggleLabel';
import AddRouteName from '../Labeling/AddRouteName';
import { EditableCell, EditableRow } from '../EditableTable/EditableTable';
import { GET_STATIONS } from '../../queries';
import { useQuery, useMutation } from '@apollo/client';
import { UPDATE_LABEL } from '../../mutations';
import { FilterOutlined, FilterFilled } from '@ant-design/icons';

const LabelTable = ({ refetch, labelsData, selectedStation }) => {
    const [rows, setRows] = useState([]);
    const [originalRows, setOriginalRows] = useState([]);
    const [searchText, setSearchText] = useState('');
    const { data: stationsData } = useQuery(GET_STATIONS);
    const [selectedLabel, setSelectedLabel] = useState([]);
    const [filteredValues, setFilteredValues] = useState([true]);
    const [updateLabel] = useMutation(UPDATE_LABEL, {
        onCompleted: (data) => {
            message.success('メモを保存しました。');
            refetch();
        },
        onError: (error) => {
            console.log(error);
        }
    });
    const rowSelection = {
        selectedLabel,
        hideSelectAll: true,
        onChange: selectedRowKeys => setSelectedLabel(selectedRowKeys),
    };

    const handleSearchChange = e => {
        const { value } = e.target;

        setSearchText(value);

        if (value !== '') {
            setRows(originalRows.filter(row => {
                return row.label.toLowerCase().includes(value.toLowerCase())
                    || toFullWidthKatakana(row.label.toLowerCase()).includes(value.toLowerCase())
                        || toHalfWidthKatakana(row.label.toLowerCase()).includes(value.toLowerCase());
            }));
        } else {
            setRows(originalRows);
        }
    };

    useEffect(() => {
        if (labelsData) {
            const parsedRows = labelsData.mostFrequentlyUsedLabels.map(label=> ({
                key: label.id,
                id: label.id,
                memo: label.memo,
                label: label.label.join('|'),
                enabled: label.enabled,
                user: label.user
            }));
            setRows(parsedRows);
            setOriginalRows(parsedRows);
        }
    }, [labelsData])

    const taggify = enabled => {
        if (enabled) {
            return <Tag color='green'>表示中</Tag>
        } else {
            return <Tag color='orange'>非表示</Tag>
        }
    };

    const handleSave = (row) => {
        const newData = [...rows];
        const index = newData.findIndex((item) => row.key === item.key);
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setRows(newData);
        updateLabel({
            variables: {
                input: {
                    id: row.id, memo: row.memo
                }
            }
        });
    };

    const labelColumns = [
		{
			title: <Typography.Text strong>ルートID</Typography.Text>,
			dataIndex: "id",
			sorter: (a, b) => a.id - b.id,
		},
		{
			title: <Typography.Text strong>ルート名</Typography.Text>,
			dataIndex: "label",
			sorter: (a, b) => a.label.localeCompare(b.label, "ja"),
		},
		{
			title: <Typography.Text strong>メモ</Typography.Text>,
			dataIndex: "memo",
			render: (text, rowContent) => rowContent.memo || "",
			editable: true,
		},
		{
			title: <Typography.Text strong>作成者</Typography.Text>,
			dataIndex: "user",
			render: (text, rowContent) => (rowContent.user ? `${rowContent.user.lastName} ${rowContent.user.firstName}` : ""),
		},
		{
			title: <Typography.Text strong>ステータス</Typography.Text>,
			dataIndex: "enabled",
            align: "right",
			filters: [
				{
					text: "表示中",
					value: true,
				},
				{
					text: "非表示",
					value: false,
				},
			],
            filteredValue: filteredValues,
            onFilterChange: (filteredValues) => {
                setFilteredValues(filteredValues);
            },
            filterIcon: (filtered) => filtered ? <FilterFilled style={{fontSize: "20px", color: "#1890ff"}}/> : <FilterOutlined style={{ fontSize: "20px" }} />,
			onFilter: (value, record) => record.enabled === value,
			render: (text, rowContent) => taggify(rowContent.enabled),
		},
	];

    const columns = labelColumns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave,
            }),
        };
    });

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };


    return <>
        <Col span={5} style={{ padding: '10px 24px 0px', background: '#fff' }}>
            <Input.Search placeholder="検索" value={searchText} onChange={handleSearchChange} />
        </Col>
        <Col span={19}>
            <Row justify='end' style={{ padding: '10px 24px 0px', background: '#fff' }}>
                <Space>
                    <AddRouteName refetch={refetch} stationsData={stationsData} selectedStation={selectedStation}/>
                    <ToggleLabel refetch={refetch} selectedIds={selectedLabel} type='enable' />
                    <ToggleLabel refetch={refetch} selectedIds={selectedLabel} type='disable' />
                </Space>
            </Row>
        </Col>
        <Table
            components={components}
            rowSelection={rowSelection}
            rowClassName={() => 'editable-row'}
            columns={columns}
            style={{ padding: '24px', background: '#fff' }}
            sticky
            scroll={{ y: 700 }}
            pagination={false}
            dataSource={rows}
            onChange={(_, filters) => {
                setFilteredValues(filters.enabled);
            }}
        />
    </>
};

export default LabelTable;
