import React, { useState } from 'react';
import { Button, message } from 'antd';
import { SignatureOutlined, LoadingOutlined } from '@ant-design/icons';
import { CREATE_AUTO_LABEL } from '../../mutations';
import { useMutation, useQuery, useApolloClient } from '@apollo/client';
import { GET_LATEST_SUCCESS_MODELS, GET_AUTO_LABELS } from '../../queries';

const AutoLabelButton = ({selectedDate, selectedStation, refetch}) => {
    const client = useApolloClient();
    const [isProcessing, setIsProcessing] = useState(false);
    const [createAutoLabel] = useMutation(CREATE_AUTO_LABEL);
    const { data: labelModelData } = useQuery(GET_LATEST_SUCCESS_MODELS, {
        fetchPolicy: 'network-only',
    });

    const isModelAvailable = React.useMemo(() => {
        if (!labelModelData?.getLatestSuccessModels || !selectedStation) return false;
        return labelModelData.getLatestSuccessModels.some(
            (model) => parseInt(model.stationId) === parseInt(selectedStation)
        );
    }, [labelModelData, selectedStation]);

    const checkAutoLabelStatus = async (autoLabelId) => {
        try {
            const { data } = await client.query({
                query: GET_AUTO_LABELS,
                fetchPolicy: 'network-only'
            });

            const autoLabel = data?.autoLabels?.edges?.find(
                edge => edge.node.id === autoLabelId
            );

            if (!autoLabel) {
                message.error('自動ラベル付けの状態を取得できませんでした。');
                setIsProcessing(false);
                return;
            }

            const status = autoLabel.node.status;
            console.log(`AutoLabel status for ${autoLabelId}: ${status}`);

            if (status === 'success') {
                try {
                    await refetch(); // This will now use the correct refetch function
                    message.success('自動ラベル付けが完了しました。');
                    setIsProcessing(false);
                    return true;
                } catch (error) {
                    console.error('Error refetching data:', error);
                    message.error('データの更新に失敗しました。');
                    setIsProcessing(false);
                    return true;
                }
            } else if (status === 'failed') {
                message.error('自動ラベル付けに失敗しました。');
                setIsProcessing(false);
                return true;
            }

            return false;
        } catch (error) {
            console.error('Error checking AutoLabel status:', error);
            message.error('自動ラベル付けの状態を取得できませんでした。');
            setIsProcessing(false);
            return true;
        }
    };

    const pollAutoLabelStatus = async (autoLabelId, maxAttempts = 6) => {
        let attempts = 0;
        const pollInterval = 10000; // 10 seconds

        const poll = async () => {
            if (attempts >= maxAttempts) {
                message.error('自動ラベル付けの完了を待機中にタイムアウトしました。');
                setIsProcessing(false);
                return;
            }

            const isComplete = await checkAutoLabelStatus(autoLabelId);
            if (!isComplete) {
                attempts++;
                setTimeout(poll, pollInterval);
            }
        };

        await poll();
    };

    const handleClick = async () => {
        try {
            setIsProcessing(true);
            const { data } = await createAutoLabel({
                variables: {
                    input: {
                        stationId: selectedStation,
                        startDate: selectedDate,
                        endDate: selectedDate
                    }
                }
            });

            if (data?.createAutoLabel?.autoLabel?.id) {
                message.info('自動ラベル付けを開始しました。');
                await pollAutoLabelStatus(data.createAutoLabel.autoLabel.id);
            } else {
                message.error('自動ラベル付けの開始に失敗しました。');
                setIsProcessing(false);
            }
        } catch (error) {
            console.error('Error creating AutoLabel:', error);
            message.error('自動ラベル付けの開始に失敗しました。');
            setIsProcessing(false);
        }
    }

    return <Button
            icon={isProcessing ? <LoadingOutlined style={{ marginRight: 8, fontSize: '20px' }} /> : <SignatureOutlined style={{ marginRight: 8, fontSize: '20px' }} />}
            onClick={handleClick}
            disabled={!isModelAvailable || isProcessing}
            title={!isModelAvailable ? "ラベルモデルが利用できません。モデル管理画面でモデルを作成してください。" : ""}
            >
            {isProcessing ? "AIラベル付け中..." : "AIラベル付け"}
            </Button>
};

export default AutoLabelButton; 