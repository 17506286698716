import React, { useEffect, useState } from 'react';
import './dashboard.less';
import {
    Col,
    Empty,
    DatePicker,
    Radio,
    Row,
    Space,
    Card,
    Typography,
    Table,
    Badge,
    Input
} from 'antd';
import { useLazyQuery, useQuery } from '@apollo/client';
import Plot from 'react-plotly.js';
import { GET_STATIONS, GET_DASHBOARD_ROUTES_COUNT, GET_DASHBOARD_TRUCKS_COUNT } from '../../queries';
import * as moment from 'moment';
import { useStickyState } from '../../Utilities/useStickyState';
import { useHistory } from 'react-router-dom';
import { localeInDatepicker } from '../../Utilities/datepickerWithTimeInForm';

const Dashboard = () => {
    const [date, setDate] = useState(moment().add(1, 'days'));
    const [referenceMonth, setReferenceMonth] = useState(moment().add(1, 'days').format('YYYY-MM'));
    const [thresholdPercent, setThresholdPercent] = useStickyState(90.0, 'dashboardThresholdPercent');
    const [dow, setDow] = useStickyState('all', 'dashboardDow');
    const [n, setN] = useStickyState('3', 'dashboardN');
    const { data, loading } = useQuery(GET_STATIONS);
    const [getDashboardRoutesCount, { data: dashboardRoutesCountData }] = useLazyQuery(GET_DASHBOARD_ROUTES_COUNT);
    const [getDashboardTrucksCount, { data: dashboardTrucksCountData }] = useLazyQuery(GET_DASHBOARD_TRUCKS_COUNT);
    const [rows, setRows] = useState([]);
    const [plotData, setPlotData] = useState(null);
    const history = useHistory();
    const columns = [
        {
            title: <Typography.Text strong>営業所</Typography.Text>,
            dataIndex: 'name'
        },
        {
            title: <Typography.Text strong><Badge color={'green'}/>割付完了</Typography.Text>,
            dataIndex: 'assignedRoutes'
        },
        {
            title: <Typography.Text strong><Badge color={'red'}/>割付未完了</Typography.Text>,
            dataIndex: 'unassignedRoutes'
        },
        {
            title: <Typography.Text strong><Badge color={'yellow'}/>借トラック</Typography.Text>,
            dataIndex: 'transferedRoutes'
        },
    ]

    useEffect(() => {
        if (data) {
            let tempRows = data.stations.edges.map(station => ({
                key: station.node.id,
                id: station.node.id,
                name: station.node.officialName,
                assignedRoutes: 0,
                unassignedRoutes: 0,
                transferedRoutes: 0
            }));

            if (dashboardRoutesCountData) {
                JSON.parse(dashboardRoutesCountData.dashboardRoutesCount).forEach(
                    dashboardRouteCount => {
                        const stationId = dashboardRouteCount[0]
                        // find row with this stationId
                        tempRows.forEach(row => {
                            if (row.id === String(stationId)) {
                                row[dashboardRouteCount[1]] = dashboardRouteCount[2]
                            }
                        });
                });
            }
            setRows(tempRows);

            if (dashboardTrucksCountData) {
                let emptyTrucksCount = JSON.parse( dashboardTrucksCountData.dashboardTrucksCount);
                const yearMonths = [ ...new Set(emptyTrucksCount.map((h) => h.yearmonth)), ].sort().slice(-n);
                const dashboardYearMonths = yearMonths.slice( Math.max(yearMonths.length - n, 0));
                const filteredTruckCounts = emptyTrucksCount.filter( (h) => h.dow_filter === dow &&
                        dashboardYearMonths.includes(h.yearmonth)
                );
                const stationNames = [ ...new Set(filteredTruckCounts.map((h) => h.station_name.replace('ハブセンター', ''))), ].sort();
                const barchartData = yearMonths.map((yearMonth) => {
                    const yearMonthTruckCounts = filteredTruckCounts.filter(
                        (h) => h.yearmonth === yearMonth
                    );
                    return {
                        x: stationNames,
                        y: stationNames.map((stationName) => {
                            const yearMonthTruckCount = yearMonthTruckCounts.find( (h) => h.station_name.replace('ハブセンター', '') === stationName);
                            return yearMonthTruckCount ? yearMonthTruckCount.empty_trucks : 0;
                        }),
                        name: yearMonth,
                        type: "bar",
                    };
                });

                setPlotData(barchartData);
            }
        }
    }, [data, dashboardRoutesCountData, dashboardTrucksCountData, dow, referenceMonth, n])

    useEffect(() => {
        getDashboardRoutesCount({ variables: { opsDate: `${date}` } });
        getDashboardTrucksCount({ variables: { referenceMonth: `${referenceMonth}`, threshold: thresholdPercent / 100 } });
    }, [getDashboardRoutesCount, getDashboardTrucksCount, referenceMonth, date, dow, n, thresholdPercent]);

    const onReferenceMonthChange = (date, dateString) => {
        setReferenceMonth(dateString);
    }

    const onDowChange = (e) => {
        setDow(e.target.value);
    }

    const onNChange = (e) => {
        setN(parseInt(e.target.value));
    }

    const makeDividerLines = (plotData) => {
        const lines = [];
        if (plotData) {
            const stationNum = plotData[0].x.length;
            for (let i = 0; i < stationNum -1; i++) {
                lines.push({
                    type: 'line',
                    yref: 'paper', // relative to the plot instead of y axis
                    line: {
                        width: 0.5
                    },
                    x0: i + 0.5,
                    y0: -0.05,
                    x1: i + 0.5,
                    y1: 1
                });
            }
        }
        return lines;
    }

    const handlePlotClick = e => {
        const stationName = e.points[0].x;
        const month = e.points[0].data.name
        const path = `/truckStats/${month}/${dow}/${stationName}/${thresholdPercent}`
        history.push(path)
    }

    return <>
        <Space size={24} style={{ width: '100%' }} direction='vertical'>
            { loading && <Empty/> }
            { !loading &&
                <>
                <Row gutter={[16,8]}>
                    <Col span={24}>
                        <Card bordered={false}>
                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <Typography.Title level={4}>営業所別空車状況グラフ</Typography.Title>
                            <Space direction='horizontal' size='large'>
                                <Radio.Group onChange={onDowChange} defaultValue={dow}>
                                    <Radio.Button value="all">全て</Radio.Button>
                                    <Radio.Button value="weekday">平日</Radio.Button>
                                </Radio.Group>
                                <Input
                                    type="number"
                                    min="0"
                                    max="100"
                                    defaultValue={thresholdPercent}
                                    onChange={(e) => setThresholdPercent(parseFloat(e.target.value))}
                                    prefix="空車率"
                                    suffix="%"
                                />
                                <DatePicker 
                                    locale={localeInDatepicker}
                                    onChange={onReferenceMonthChange} 
                                    picker="month" 
                                    defaultValue={moment(referenceMonth, 'YYYY-MM')}
                                    disabledDate={(current) => {
                                        return current && current > moment().endOf('day');
                                    }}/>
                                <Radio.Group onChange={onNChange} defaultValue={n.toString()}>
                                    <Radio.Button value="1">過去1ヶ月</Radio.Button>
                                    <Radio.Button value="2">過去2ヶ月</Radio.Button>
                                    <Radio.Button value="3">過去3ヶ月</Radio.Button>
                                </Radio.Group>
                            </Space>
                            </div>
                            <Plot
                                data={plotData}
                                onClick={handlePlotClick}
                                layout= {{
                                    barmode: 'group',
                                    bargap: 0.05,
                                    bargroupgap: 0.1,
                                    width: 1000,
                                    shapes: [
                                        ...makeDividerLines(plotData)
                                    ],
                                    legend: {
                                        orientation: "h",
                                        yanchor: "bottom",
                                        y: 1.02,
                                        xanchor: "right",
                                        x: 1
                                    }
                                }}
                            />
                        </Card>
                    </Col>
                </Row>
                <Row gutter={[16,8]}>
                    <Col span={24}>
                        <Card bordered={false}>
                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                <Typography.Title level={4} style={{marginBottom: '1.5rem'}}>ルート割付状況</Typography.Title>
                                <div>
                                    表示期間:{' '}
                                    <DatePicker
                                        disabledDate={ (current) => { return current && current < moment().endOf('day'); }}
                                        defaultValue={date}
                                        onChange={ (date, dateString) => { setDate(dateString); }}
                                        locale={localeInDatepicker}
                                    />
                                </div>
                            </div>
                            <Table
                                pagination={false}
                                columns={columns}
                                dataSource={rows}
                            />
                        </Card>
                    </Col>
                </Row>
                </>
            }
        </Space>
    </>;
}

export default Dashboard;
