import { Button, DatePicker, Space, Tabs, Input, Row, message, Table, Dropdown } from 'antd';
import React, { useMemo } from 'react';
import { uploadToS3, useS3Upload } from '../../Utilities/useS3Upload';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { GET_PRESIGNED_UPLOAD_URL, GET_MASTER_FILES } from '../../queries';
import { CREATE_MASTER_FILE } from '../../mutations';
import localeInDatepicker from 'antd/es/date-picker/locale/ja_JP';
import iconv from 'iconv-lite';

const MasterDataTab = ({ type }) => {
    const [files, setFiles ] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [search, setSearch] = React.useState('');
    const [date, setDate] = React.useState(null);
    
    const [createMasterFile] = useMutation(CREATE_MASTER_FILE,
        {
            onCompleted: () => {
                refetch();
            }
        }
    );
    
    const { data, refetch } = useQuery(GET_MASTER_FILES, {
        variables: { dataType: type }
    });
    
    const validateColumnNames = (columns) => {
        const requiredColumns = {
            'drive': ['運行ID', '車両番号', '乗務員コード', '所属名称', '作業名称', '到着時刻', '出発時刻'],
            'truck': ['車両番号', '営業所', '車種名', '最大積載', '免許区分', '屯車', '登録番号'], 
            'station': ['station_name']
        }
        
        const missingColumns = requiredColumns[type].filter(column => {
            const strippedColumns = columns.map(c => c.replace(/['"\\]/g, ''));
            return !strippedColumns.includes(column);
        });
        if (missingColumns.length > 0) {
            message.error('CSVファイルに以下のカラムが含まれていません: ' + missingColumns.join(', '));
            return false;
        }
        return true;
    }
    
    const filteredData = useMemo(() => {
        if (!data) {
            return [];
        }
        let filteredRows = [...data.masterFiles]
        if (search) {
            filteredRows = filteredRows.filter(row => row.filename.includes(search));
        }
        if (date) {
            filteredRows = filteredRows.filter(row => row.createdAt.includes(date));
        }
        return filteredRows;
    }, [data, search, date]);
    
    const [getPresignedUploadUrl] = useLazyQuery(GET_PRESIGNED_UPLOAD_URL, {
        onCompleted: (data) => {
            const newfiles = [...files];
            const [file, originalName] = newfiles.shift();
            setFiles(newfiles);
            const uploadUrl = data.presignedUploadUrl.uploadUrl
            if (file) {
                setLoading(true)
                uploadToS3(
                    {
                        file,
                        presignedUploadUrl: uploadUrl,
                        onError: (response) => {
                            console.log('ERROR!' + response.code);
                        },
                        onUploadReady: async () => {
                            setLoading(false)
                            message.success('アップロード完了');
                            console.log(file)
                            console.log(originalName)
                            createMasterFile(
                                {
                                    variables: { 
                                        input: {
                                            dataType: type,
                                            filename: originalName,
                                            s3ObjectKey: 'csv_uploads/' + type + '/' + file.name
                                        }
                                    }
                                }
                            );
                        }
                    }
                );
            }
        }
    });
    
    
    const { getRootProps, getInputProps } = useS3Upload({
        onUploadStart: (acceptedFiles) => {
            // Preload all files here in a stack 'files'
            // append timestamp to filename to avoid duplication
            const newFiles = acceptedFiles.map(file => {
                const n = file.name.lastIndexOf('.');
                const firstHalf = file.name.slice(0, n);
                const secondHalf = file.name.slice(n);
                const newName = firstHalf + '_' + String(Date.now()) + secondHalf;
                return [new File([file], newName, {type: file.type}), file.name];
            })
            
            const reader = new FileReader()
            reader.onload = () => {
                const maybeUtf8 = iconv.decode(Buffer.from(reader.result), 'utf-8');
                let columns = maybeUtf8.split('\n')[0].split(',');
                const concatColumns = columns.join(',');
                if (concatColumns.includes('�')) {
                    columns = iconv.decode(Buffer.from(reader.result), 'shift-jis').split('\n')[0].split(',');
                }
                
                if (!validateColumnNames(columns)) {
                    message.error('CSVファイルのカラム名が不正です');
                    return false
                }
                setFiles(newFiles);
                getPresignedUploadUrl({ variables: { objectKey: 'csv_uploads/' + type + '/'+ newFiles[0][0].name } });
            }
            reader.readAsArrayBuffer(newFiles[0][0])
        }
    });
    
    const handleSampleDownload = (sampleName) => {
        const link = document.createElement('a');
        link.href = `/samples/${sampleName}.csv`;
        link.download = `${sampleName}.csv`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    
    const columns = [
        {
            title: 'ファイル名',
            dataIndex: 'filename',
            key: 'filename',
        },
        {
            title: 'アップロード日時',
            dataIndex: 'createdAt',
            key: 'createdAt',
        },
        {
            dataIndex: 'downloadUrl',
            key: 'actions',
            render: (text) => (
                <Space size="middle">
                    <a href={text}>ダウンロード</a>
                </Space>
            ),
        },
    ];
    
    const items = [
        {
            key: 'driveData',
            label: <div onClick={() => handleSampleDownload('drive_data_sample')}>ドライブデータをダウンロード</div>
        },
        {
            key: 'driveRecord',
            label: <div onClick={() => handleSampleDownload('drive_records_sample')}>ドライブレコードをダウンロード</div>
        },
        {
            key: 'truckData',
            label: <div onClick={() => handleSampleDownload('truck_data_sample')}>トラックデータをダウンロード</div>
        },
        {
            key: 'truckMaster',
            label: <div onClick={() => handleSampleDownload('truck_master_sample')}>トラックマスタをダウンロード</div>
        }
    ]
    
    return <Space direction="vertical" style={{ width: "100%" }}>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between"}}>
            <Space>
                <Input.Search placeholder="検索" style={{ width: 400, marginRight: "1rem" }} onChange={e => setSearch(e.target.value)}/>
                <DatePicker 
                    locale={localeInDatepicker}
                    picker='date' onChange={(_, dateString) => setDate(dateString)}
                />
            </Space>
            <Space>
                <Dropdown
                    menu={{items}}
                    placement="bottomLeft"
                    arrow
                >
                    <Button type='default'>サンプルデータをダウンロード</Button>
                </Dropdown>
                <div
                    style={{marginLeft: "auto"}}
                    {...getRootProps()}>
                    <input {...getInputProps()} />
        
                    <Row justify='center'>
                        <Button loading={loading} type="primary" >CSVアップロード</Button>
                    </Row>
                </div>
            </Space>
        </div>
        <Table
            columns={columns}
            dataSource={filteredData}
            rowKey="id"
            bordered
        />
    </Space>
}

export default function MasterData() {
    
    const items = [
        {
            key: 'driveData',
            label: 'ドライブデータ',
            children: <MasterDataTab type={'drive'} title='ドライブデータ'/>,
        },
        {
            key: 'truckData',
            label: 'トラックデータ',
            children: <MasterDataTab type={'truck'} title='トラックデータ' />,
        },
        {
            key: 'stationData',
            label: '営業所データ',
            children: <MasterDataTab type={'station'} title='営業所データ'/>,
        },
    ];
    
    return (
        <div style={{ margin: "1rem 2rem" }}>
            <Tabs
                items={items}
            />
        </div>
    );
}