import React, { useState, useEffect } from 'react';
import Ellipsis from 'ant-design-pro/lib/Ellipsis';
import {
    Button,
    Input,
    Card,
    Form,
    Typography,
    Table,
    Spin,
    Space,
    Select,
    Row,
    Col,
    Divider,
    Checkbox
} from 'antd';
import { useQuery, useLazyQuery } from '@apollo/client';
import { compareFullHalfWidth } from '../../Utilities/katakanaWidthConversion';
import { GET_STATIONS, GET_LABELS, GET_ROUTES, GET_MAXIMUM_ROUTE_OPS_DATE } from '../../queries';
import AddRouteName from './AddRouteName';
import ConfirmLabelingButton from './ConfirmLabelingButton';
import EditLabelingButton from './EditLabelingButton';
import AutoLabelButton from './AutoLabelButton';
import "./labeling.css";
import dayjs from 'dayjs';
import * as moment from 'moment';
import { useStickyState } from '../../Utilities/useStickyState';
import { DatePickerWithTimeInForm } from '../../Utilities/datepickerWithTimeInForm';

const Labeling = () => {
    const { data: stationsData, loading: loadingStations } = useQuery(GET_STATIONS);
    const { data: maximumRouteDate } = useQuery(GET_MAXIMUM_ROUTE_OPS_DATE);
    const [getLabelsData, { data: labelsData, refetch: refetchLabels }] = useLazyQuery(GET_LABELS, { fetchPolicy: 'no-cache' });
    const [getRoutesData, { data: routesData, refetch: refetchRoutes }] = useLazyQuery(GET_ROUTES, { fetchPolicy: 'no-cache' });
    const [station, setStation] = useStickyState('', 'labelingStation');
    const [date, setDate] = useStickyState(moment(), 'labelingDate');
    const [labelConfidence, setLabelConfidence] = useStickyState(1.0, 'labelingLabelConfidence');
    const [filterByConfidence, setFilterByConfidence] = useState(false)
    const [rows, setRows] = useState([]);
    const [originalRows, setOriginalRows] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [selectedRoutes, setSelectedRoutes] = useState([]);
    const rowSelection = {
          selectedRoutes,
          onChange: selectedRowKeys => setSelectedRoutes(selectedRowKeys),
    };

    const handleSearchChange = e => {
        const { value } = e.target;

        setSearchText(value);

        if (value !== '') {
            setRows(originalRows.filter(row => {
                return compareFullHalfWidth(row.route, value);
            }));
        } else {
            setRows(originalRows);
        }
    };

    useEffect(() => {
        if (station !== '' && date !== '') {
            let searchString = `station_id=${station} AND ops_date=${moment(date).format("YYYY-MM-DD")}`
            if (filterByConfidence) {
                searchString += `AND label_confidence <= "${labelConfidence}"`
            }
            getRoutesData({
                variables: {
                    search: searchString
                }
            });
            getLabelsData({ variables: { stationId: station }});
        }
    }, [getRoutesData, station, date, labelConfidence, filterByConfidence, getLabelsData])

    useEffect(() => {
        if (routesData) {
            const parsedRows = routesData.routes.edges.map(route => ({
                key: route.node.id,
                id: route.node.id,
                date: route.node.opsDate,
                station: route.node.station.officialName,
                employeeName: route.node.employeeName,
                driveId: route.node.driveId,
                vehicleId: route.node.vehicleId,
                route: route.node.route,
                label: route.node.label,
                labelConfidence: route.node.labelConfidence,
                manualLabelFlag: route.node.manualLabelFlag,
                departureTime: route.node.departureTime,
                arrivalTime: route.node.arrivalTime,
            }));
            parsedRows.sort((a, b) => a.manualLabelFlag === b.manualLabelFlag ? 0 : a.manualLabelFlag ? 1 : -1);

            setRows(parsedRows);
            setOriginalRows(parsedRows);
        }
    }, [routesData])

    const columns = [
        {
            title: <Typography.Text strong>ルート</Typography.Text>,
            dataIndex: 'route',
            sorter: (a, b) => a.route - b.route,
            render: rowContent => <Ellipsis tooltip length={100}>{rowContent}</Ellipsis>,
            width: 450
        }, {
            title: <Typography.Text strong>ルート名</Typography.Text>,
            render: rowContent => <Select
                dropdownMatchSelectWidth={350}
                showSearch
                disabled={rowContent.manualLabelFlag}
                onChange={ value => {
                        setRows(rows.map(row => {
                            if (row.id === rowContent.id) {
                                row.label = JSON.parse(value)
                            }
                            return row;
                        }));
                        setOriginalRows(rows.map(row => {
                            if (row.id === rowContent.id) {
                                row.label = JSON.parse(value)
                            }
                            return row;
                        }));
                    }
                }
                style={{ width: '100%' }}
                placeholder={(rowContent.label && rowContent.label.label.join('|')) || "選択してください"}
                filterOption={(input, option) => compareFullHalfWidth(option.props.children, input)}
            >
                {
                    labelsData && labelsData.mostFrequentlyUsedLabels.map(
                        label => (
                            label.enabled &&
                                <Select.Option value={JSON.stringify(label)}>{label.label.join('|')}</Select.Option>
                        )
                    )
                }
            </Select>,
            width: 200
        },
        {
            title: <Typography.Text strong>出発時刻</Typography.Text>,
            dataIndex: 'departureTime',
            render: text => moment(text).locale("ja").format("YYYY年M月D日 H:mm"),
            width: 125
        }, {
            title: <Typography.Text strong>到着時刻</Typography.Text>,
            dataIndex: 'arrivalTime',
            render: text => moment(text).locale("ja").format("YYYY年M月D日 H:mm"),
            width: 125
        }, 
        {
            title: <Typography.Text strong>配送者</Typography.Text>,
            dataIndex: 'employeeName',
            sorter: (a, b) => a.employeeName.localeCompare(b.employeeName, 'ja'),
            width: 100
        }, {
            title: <Typography.Text strong>車両番号</Typography.Text>,
            dataIndex: 'vehicleId',
            width: 75
        }, {
            title: <Typography.Text strong>運行ID</Typography.Text>,
            dataIndex: 'driveId',
            width: 75
        }, {
            title: <Typography.Text strong>信頼度</Typography.Text>,
            dataIndex: 'labelConfidence',
            render: rowContent => <>{ '<' } {(parseFloat(rowContent) * 100).toFixed(1)}%</>,
            sorter: (a, b) => a.labelConfidence - b.labelConfidence,
            width: 75
        },
    ].filter(Boolean);
    const [form] = Form.useForm();

    if (loadingStations) {
        return <Spin/>;
    }

    return <>
        <Space direction='vertical' style={{ width: '100%' }}>
            <Card  bordered={false}>
                <Form form={form} layout='inline'
                    size='large'
                    onFinish={
                        ({ date, station, labelConfidence }) => {
                            setStation(station);
                            setDate(date.format("YYYY-MM-DD"));
                            setLabelConfidence(labelConfidence || 1.0);
                        }
                    }
                >
                    <Row gutter={[16,8]}>
                        <Col>
                            <Form.Item name='station' label="営業所" initialValue={station}
                                rules={[{ required: true, message: '営業所を選択してください' }]}
                            >
                                <Select
                                    showSearch
                                    placeholder="選択してください"
                                    style={{ width: 200 }}
                                >
                                    {
                                        stationsData.stations.edges.map(
                                            station => (
                                                <Select.Option key={station.node.id} value={station.node.id}>
                                                    {station.node.officialName}
                                                </Select.Option>
                                            )
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col>
                            <DatePickerWithTimeInForm 
                                label="表示期間"
                                name='date'
                                initialTime={dayjs(date)}
                                disableDate={current => (current > dayjs(maximumRouteDate).endOf('day'))}
                                format="YYYY-MM-DD"
                                showTime={false}
                            />
                        </Col>
                        <Col>
                            <Checkbox
                                checked={filterByConfidence}
                                onChange={(e) => setFilterByConfidence(e.target.checked)}
                            >
                                フィルターを有効化
                            </Checkbox>
                        </Col>
                        <Col>
                            <Form.Item name='labelConfidence' label="信頼度" initialValue={labelConfidence}
                                rules={[{ required: false, message: '信頼度を選択してください' }]}
                            >
                                <Select
                                    disabled={!filterByConfidence}
                                    showSearch
                                    placeholder="選択してください"
                                    defaultValue={1.0}
                                    style={{ width: 200 }}
                                >
                                    {
                                        [0.1, 0.3, 0.5, 0.7, 0.8, 0.9, 0.95, 0.975, 0.99, 1.0].map(
                                            confidence => (
                                                <Select.Option key={confidence} value={confidence}>
                                                    { '<' } {confidence*100}%
                                                </Select.Option>
                                            )
                                        ) 
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item shouldUpdate>
                                {
                                    () => (
                                        <Button 
                                            htmlType="submit" type='primary'>
                                            検索
                                        </Button>
                                    )
                                }
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Card>
            <Divider style={{ margin: 0, padding: 0 }}/>
            <Row>
                <Col span={5} style={{ padding: '24px 24px 0px', background: '#fff' }}>
                    <Input.Search placeholder="ルート検索" value={searchText} onChange={handleSearchChange} />
                </Col>
                <Col span={19}>
                    <Row justify='end' style={{ padding: '24px 24px 0px', background: '#fff' }}>
                        <Space>
                            <div>
                                ルート数 : { rows.length }
                            </div>
                            <AddRouteName refetch={refetchLabels} stationsData={stationsData} selectedStation={station}/>
                            <AutoLabelButton selectedDate={date} selectedStation={station} refetch={refetchRoutes}/>
                            <EditLabelingButton selectedRoutes={selectedRoutes} rows={rows} setRows={setRows} />
                            <ConfirmLabelingButton refetch={refetchRoutes} selectedRoutes={selectedRoutes} rows={rows} />
                        </Space>
                    </Row>
                </Col>
                <Table
                    rowSelection={rowSelection}
                    columns={columns}
                    rowClassName={record => record.manualLabelFlag ? "disabled-row" : 'enabled-row' }
                    style={{ padding: '24px', background: '#fff' }}
                    sticky
                    scroll={{ y: 700 }}
                    pagination={false}
                    dataSource={rows}
                />
            </Row>
        </Space>
    </>
};

export default Labeling;
