import React, { useState, useEffect } from 'react';
import { Alert, Button, Typography, Row, Col, Space, Input, Table, Skeleton, Checkbox, message } from 'antd';
import { PlusOutlined, EditOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { UPDATE_STATION } from '../../mutations'
import { authenticationService } from '../../Utilities/authenticationService';
import history from '../../Utilities/history';
import AddStationModal from './AddStationModal';
import EditStationModal from './EditStationModal';
import NewStationModal from './NewStationModal';
import DeleteStationsButton from './DeleteStationsButton';
import { GET_ALL_STATIONS } from '../../queries';

const StationManagement = () => {
    const [currentModal, setCurrentModal] = useState('table');
    const [newStationCreated, setNewStationCreated] = useState(false);
    const [stationsDeleted, setStationsDeleted] = useState(false);
    const [selectedStationIds, setSelectedStationIds] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [stationToEdit, setStationToEdit] = useState(null);
    const { data, error, loading, refetch } = useQuery(GET_ALL_STATIONS);
    const [updateStation] = useMutation(UPDATE_STATION, {
        onCompleted: (data) => {
            refetch();
        }
    });

    const [rows, setRows] = useState([]);

    const districtOptions = data?.allStations.edges.map(station => {
        return {
            text: station.node.district,
            value: station.node.district,
        }
    }).filter((value, index, self) => self.findIndex(t => t.value === value.value) === index).filter(Boolean)

    const regionOptions = data?.allStations.edges.map(station => {
        return {
            text: station.node.region,
            value: station.node.region,
        }
    }).filter((value, index, self) => self.findIndex(t => t.value === value.value) === index).filter(Boolean)
    
    const prefectureOptions = data?.allStations.edges.map(station => {
        return {
            text: station.node.prefecture,
            value: station.node.prefecture,
        }
    }).filter((value, index, self) => self.findIndex(t => t.value === value.value) === index).filter(Boolean)
    
    const columns = [
        {
            title: <Typography.Text strong>営業所</Typography.Text>,
            dataIndex: 'stationName'
        },
        {
            dataIndex: 'officialName',
            title: <Typography.Text strong>営業所(official)</Typography.Text>,
        },
        {
            dataIndex: 'isOpen',
            title: <Typography.Text strong>営業中</Typography.Text>,
            filters: [
                {
                    text: '営業中',
                    value: true,
                },
                {
                    text: '非営業中',
                    value: false,
                },
            ],
            onFilter: (value, record) => record.isOpen === value,
            render: (text, record) => {
                const stationName = record.stationName
                const id = record.key
                const handleOpenSelect = (value) => {
                    updateStation({ variables: { input: { id, stationName, isOpen: value.target.checked }}})
                }
                return (
                    <Checkbox onChange={handleOpenSelect} checked={record.isOpen}></Checkbox>
                )
            }
        },
        {
            dataIndex: ['company', 'companyName'],
            title: <Typography.Text strong>会社ID</Typography.Text>,
            
        },
        {
            dataIndex: 'longtitude',
            title: <Typography.Text strong>経度</Typography.Text>,
        },
        {
            dataIndex: 'latitude',
            title: <Typography.Text strong>緯度</Typography.Text>,
        },
        {
            dataIndex: 'prefecture',
            title: <Typography.Text strong>都道府県</Typography.Text>,  
            filters: prefectureOptions,
            onFilter: (value, record) => record.prefecture === value,
            filterSearch: true,
        },
        {
            dataIndex: 'region',
            title: <Typography.Text strong>地域</Typography.Text>,
            filters: regionOptions,
            onFilter: (value, record) => record.region === value,
            filterSearch: true,
        },
        {
            dataIndex: 'district',
            title: <Typography.Text strong>地区</Typography.Text>,
            filters: districtOptions,
            onFilter: (value, record) => record.district === value,
            filterSearch: true,
        },
        {
            title: <Typography.Text strong>編集</Typography.Text>,
            key: 'action',
            render: (_, record) => (
                <Button 
                    icon={<EditOutlined />}
                    onClick={() => {
                        setStationToEdit(record);
                        setCurrentModal('editStation');
                    }}
                />
            ),
        },
    ];


    useEffect(() => {
        if (!loading) {
            setRows(
                data.allStations.edges.map(
                    station => {
                        if (station.node.stationName.includes(searchText)) {
                            return {
                                key: station.node.id,
                                stationName: station.node.stationName,
                                officialName: station.node.officialName,
                                isOpen: station.node.isOpen,
                                companyId: station.node.companyId,
                                longtitude: station.node.longtitude,
                                latitude: station.node.latitude,
                                prefecture: station.node.prefecture,
                                region: station.node.region,
                                district: station.node.district,
                                company: station.node.company
                            }
                        } else {
                            return false;
                        }
                    }
                ).filter(Boolean).sort((a, b) => a.key - b.key)
            )
        }
    }, [searchText, data, loading])


    if (loading) { return <Skeleton />; }

    if (error) {
        authenticationService.logout();
        history.push('/login');
        return <Skeleton />
    }

    const handleSearch = searchField => {
        setSearchText(searchField.target.value);
    };

    const currentUser = JSON.parse(localStorage.currentUser);
    
    const TableHeader = <React.Fragment>
        <Row>
            <Col align='left' style={{ padding: '0px', background: '#fff' }}>
                <Input.Search onChange={handleSearch} allowClear placeholder="検索" />
            </Col>
            {  (currentUser?.admin || currentUser?.company_admin) && <Col style={{flex: 1}} align='right'>
                <Space>
                    <Button onClick={ () => setCurrentModal('addStation') } icon={<PlusOutlined/>}>営業所名追加</Button>
                    <DeleteStationsButton
                        selectedStationIds={selectedStationIds}
                        handleCompleted={ (stationInfo) => { setCurrentModal('table'); setStationsDeleted(stationInfo); refetch(); }} />
                </Space>
            </Col>}
        </Row>
        <Row>
            { newStationCreated &&
                <>
                    <Col span={24}>
                        <Alert message="営業所を追加しました。" type="success" showIcon closable/>
                    </Col>
                    <NewStationModal stationInfo={newStationCreated} />
                </>
            }
            { stationsDeleted &&
                <>
                    <Col span={24}>
                        <Alert
                            message={`"${stationsDeleted.map(c => c.stationName)}"を削除しました。`}
                            type="success"
                            showIcon
                            closable
                        />
                    </Col>
                </>
            }
        </Row>
    </React.Fragment>;

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedStationIds(selectedRows.map(row => row.key));
        }
    };

    const returnToTable = () => {
        setCurrentModal('table');
    };

    return <React.Fragment>
        { currentModal === 'table' &&
            <Table
                columns={columns}
                dataSource={rows}
                rowSelection={{ type: 'checkbox', ...rowSelection }}
                title={() => TableHeader}
            />
        }
        { currentModal === 'addStation' &&
            <AddStationModal
                handleCompleted={ (stationInfo) => { setCurrentModal('table'); setNewStationCreated(stationInfo); refetch(); } }
                returnToTable={returnToTable}
            />
        }
        { currentModal === 'editStation' && stationToEdit &&
            <EditStationModal
                station={stationToEdit}
                handleCompleted={ (stationInfo) => { 
                    setCurrentModal('table'); 
                    setStationToEdit(null);
                    message.success('営業所を更新しました。');
                    refetch(); 
                }}
                returnToTable={() => {
                    setCurrentModal('table');
                    setStationToEdit(null);
                }}
            />
        }
    </React.Fragment>;
};

export default StationManagement;
