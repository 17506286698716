import React, { useEffect, useRef, useState } from 'react';
import { Drawer, List, Button, Input, Avatar, Popconfirm, Form } from 'antd';
import { Comment } from '@ant-design/compatible';
import { authenticationService } from '../../Utilities/authenticationService';
import { isMobile } from '../../Utilities/isMobile';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';

const CommentDrawer = ({ open, setOpen, comments, selectedCommenterId, toUser, postingId, createComment, updateComment, deleteComment }) => {
    const commentsEndRef = useRef(null);
    const currentUser = authenticationService.currentUserValue;
    const [newComment, setNewComment] = useState('');    
    const filteredComments = comments.filter(comment => (comment.toId === selectedCommenterId && comment.fromId === String(currentUser.id)) || (comment.toId === String(currentUser.id) && comment.fromId === selectedCommenterId))
    const [editComment, setEditComment] = useState(false);
    const [editCommentId, setEditCommentId] = useState(null);
    const [editInput, setEditInput] = useState('');

    const onSubmit = () => {
        createComment ({
            variables: {
                input: {
                    postingId: postingId,
                    commentText: newComment,
                    toId: selectedCommenterId
                }
            }
        })
        setNewComment('');
    }

    useEffect(() => {
        if (open) {
            setTimeout(() => {
                if (commentsEndRef.current) {
                    commentsEndRef.current.scrollIntoView({ behavior: 'smooth' });
                }
            }, 100);
        }
    }, [open, comments]);

    return (
        <Drawer
            anchor="right"
            open={open}
            onClose={() => setOpen(false)}
            title={toUser?.lastName}
            style={{ zIndex: 1003 }}
            width={isMobile() ? '100vw': '400px'}
        >
            <div style={{ width: '100%', zIndex: 1 }}>
                <div style={{ flex: '1 1 auto', overflowY: 'auto', paddingBottom: '60px' }}>
                    <List>
                        {filteredComments.map((comment) => (<div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: String(comment.fromId) === String(currentUser?.id) ? 'flex-end' : 'flex-start '}}>
                            <Comment
                                author={comment.fromId === selectedCommenterId ? toUser?.lastName : null}
                                avatar={comment.fromId === selectedCommenterId ? <Avatar>{toUser?.lastName[0]}</Avatar> : null}
                                style={{ textAlign: String(comment.fromId) === String(currentUser?.id) ? 'right' : 'left' }}
                                content={editComment && comment.id === editCommentId ?
                                    <Form onFinish={
                                        ({ editedComment }) => {
                                            if (editComment && editedComment !== editInput) {
                                                updateComment({
                                                    variables: {
                                                        input: {
                                                            id: editCommentId,
                                                            commentText: editedComment
                                                        }
                                                    }
                                                })
                                            }
                                            setEditComment(false);
                                            }
                                        }
                                        style={{ width: '100%'}}
                                    >
                                        <Form.Item name='editedComment'>
                                            <div style={{ width: '100%' }}>
                                                <Input.TextArea
                                                    autoSize={{ minRows: 2, maxRows: 6 }}
                                                    defaultValue={editInput}
                                                />
                                            </div>
                                        </Form.Item>
                                        <Form.Item>
                                            <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%'}}>
                                                <Button htmlType="button" onClick={() => setEditComment(false)} style={{ marginRight: '1rem'}}>
                                                    キャンセル
                                                </Button>
                                                <Button htmlType="submit" type="primary">
                                                    保存
                                                </Button>
                                            </div>
                                        </Form.Item>
                                    </Form>
                                    :
                                    comment.content
                                }
                            />
                            {String(comment.fromId) === String(currentUser?.id) &&
                                <div style={{ display: 'flex', flexDirection: 'column'}}>
                                    <EditOutlined
                                        key="edit"
                                        onClick={() => {
                                            setEditComment(true);
                                            setEditCommentId(comment.id);
                                            setEditInput(comment.content.props?.children[0]?.props?.children)
                                        }}
                                        style={{ fontSize: 16, marginBottom: '.5rem'}}
                                    />
                                    <Popconfirm
                                        title="コメントを削除しますか？"
                                        okText="削除"
                                        cancelText="キャンセル"
                                        onConfirm={() => {
                                            deleteComment({
                                                variables: {
                                                    input: {
                                                        id: comment.id
                                                    }
                                                }
                                            })
                                        }}
                                    >
                                        <DeleteOutlined style={{ fontSize: 16}}/>
                                    </Popconfirm>
                                </div>
                            }
                        </div>))}
                    </List>
                    <div ref={commentsEndRef} />
                </div>
                <div
                    style={{
                        position: 'fixed',
                        bottom: 0,
                        left: isMobile() ? 0 : 'calc(100% - 400px)',
                        width: isMobile() ? '100%' : '400px',
                        display: 'flex',
                        flexDirection: 'row'
                    }}
                >
                    <Input.TextArea
                        rows={4}
                        value={newComment}
                        onChange={(e) => setNewComment(e.target.value)}
                    />
                    <Button
                        style={{ height: "auto" }}
                        type="primary"
                        onClick={() => {
                            onSubmit();
                            setNewComment('');
                        }}  
                    >
                        送信
                    </Button>
                </div>
            </div>
        </Drawer>
    );
}

export default CommentDrawer;

