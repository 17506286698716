import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { CREATE_TRUCK_POSTING_COMMENT,
        UPDATE_TRUCK_POSTING_COMMENT,
        DELETE_TRUCK_POSTING_COMMENT
 } from '../../mutations';
import { GET_TRUCK_POSTING_COMMENT, GET_USERS } from '../../queries';
import { authenticationService } from '../../Utilities/authenticationService';
import {
    Button,
    Drawer,
    Typography,
    Avatar,
    Divider,
    Popconfirm,
    Row,
    Col,
    Tag,
    Select,
    Input,
    message
} from 'antd';
import { EditOutlined, MessageOutlined, DeleteOutlined } from '@ant-design/icons';
import moment from 'moment';
import { isMobile } from '../../Utilities/isMobile';
import { taggify } from '../../Utilities/taggify';
import CommentList from '../FreightPosting/CommentList';
import CommentDrawer from '../FreightPosting/CommentDrawer';


const DetailDrawer = ({ visible=false, setVisible, updatePosting,  posting = {}, refetchPostings, buttonMode, traboxButton }) => {
    const currentUser = authenticationService.currentUserValue
    const [commenterIds, setCommenterIds] = useState([]);
    const [comments, setComments] = useState([]);
    const [selectedCommenterId, setSelectedCommenterId] = useState();
    const [openCommentDrawer, setOpenCommentDrawer] = useState(false);
    const [commenterUsers, setCommenterUsers] = useState([]);
    const [memo, setMemo] = useState(posting.memo);
    const [deliverer, setDeliverer] = useState();
    const { loading: userListLoading, error: userListError, data: userListData } = useQuery(GET_USERS, {
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true
    });

    const { refetch: refetchComments } = useQuery(GET_TRUCK_POSTING_COMMENT, {
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        variables: { id: parseInt(posting.id) },
        onCompleted: (data) => {
            const fromUsers = data.posting?.truckPostingComments.map(comment => comment.fromUser)
            const toUsers = data.posting?.truckPostingComments.map(comment => comment.toUser)
            setCommenterUsers([...new Set([...fromUsers, ...toUsers])].filter(commenter => String(commenter.id) !== String(posting?.userId)))
            const fromUserIds = data.posting?.truckPostingComments.map(comment => comment.fromUser.id)
            const toUserIds = data.posting?.truckPostingComments.map(comment => comment.toUser?.id)
            const commenters = [...new Set([...fromUserIds, ...toUserIds])].filter(commenter => String(commenter) !== String(posting?.userId))
            setCommenterIds(commenters)

            const commentArr = data.posting?.truckPostingComments.map(comment => ({
                id: comment.id,
                postingId: posting.id,
                fromId: comment.fromUser.id,
                toId: comment.toUser?.id,
                author: <Typography.Text strong>{`${comment.fromUser.lastName} ${comment.fromUser.firstName}`}</Typography.Text>,
                avatar: <Avatar>{comment.fromUser.lastName[0]}</Avatar>,
                content: (
                    <div key={comment.id} style={{ display: 'flex', flexDirection: 'column' }}>
                        <p>{comment.commentText}</p>
                        <span style={{ fontSize: '0.8em', color: '#888', alignSelf: parseInt(comment.fromUser.id) !== parseInt(currentUser.id) ? 'flex-start' : 'flex-end' }}>
                            {moment(comment.createdAt).locale('ja').format('YYYY年M月D日 H:mm')} {comment.createdAt !== comment.updatedAt && '(編集済)'}
                        </span>
                    </div>
                ),
                datetime: moment(comment.createdAt).locale('ja').format('YYYY年M月D日 H:mm'),
            }));
            setComments(commentArr.sort((a, b) => new Date(b.datetime) - new Date(a.datetime)));  
        }
    });    

    const [createTruckPostingComment] = useMutation(CREATE_TRUCK_POSTING_COMMENT, {
        onCompleted: (data) => {
            refetchPostings();
            refetchComments();
        },
        onError: (error) => {
            console.log(error);
        }
    });
    const [updateTruckPostingComment] = useMutation(UPDATE_TRUCK_POSTING_COMMENT, {
        onCompleted: (data) => {
            refetchPostings();
            refetchComments();
        },
        onError: (error) => {
            console.log(error);
        }
    });
    
    const [deleteTruckPostingComment] = useMutation(DELETE_TRUCK_POSTING_COMMENT, {
        onCompleted: (data) => {
            refetchPostings();
            refetchComments();
        },
        onError: (error) => {
            console.log(error);
        }
    });
    const mobileRowStyle = { display: 'flex', flexDirection: 'column', padding: '0.5rem 0', borderBottom: '1px solid lightgray'}
    const columnList = ['営業所', '会社名', '運行日', '出発時刻', '到着時刻', '車種', '車両番号', '積載重量', '免許タイプ', '投稿者']
    const labelNameMapping = {
        'id': 'id',
        '営業所': 'station',
        '会社名': 'companyName',
        '運行日': 'opsDate',
        '出発時刻': 'startDate',
        '到着時刻': 'endDate',
        '車種': 'vehicleType',
        '車両番号': 'vehicleId',
        '積載重量': 'maxTruckCapacity',
        '免許タイプ': 'driverLicenseClass',
        '投稿者': 'postedByUserName'
    }

    const handlePostButton = (posting) => {
        if (posting.status === "draft") {
            return <Button
            type="primary"
            onClick={() => {
                updatePosting({
                    variables: {
                        input: {
                            id: posting.id,
                            status: "available",
                            memo: memo,
                        },
                    },
                }).then(() => {
                    message.success('リクエストを投稿しました。');
                    refetchPostings();
                });
            }}
        >
            投稿する
        </Button>
        }
    }
    const handleDriverSelect = (posting) => <>
        <Select
            showSearch
            placeholder="配送者を入力する"
            optionFilterProp="label"
            onChange={(e) => {
                setDeliverer(parseInt(e));
            }}
            className={!isMobile() && "custom-select"}
            style={{ border: !isMobile() && 'none', boxShadow: !isMobile() && 'none' }}
            dropdownStyle={{ border: !isMobile() && 'none' }}
        >
            {!userListLoading && !userListError &&
                userListData?.users?.edges?.map((user) => (
                    <Select.Option
                        key={user.node.id}
                        value={user.node.id}
                        label={`${user.node.lastName} ${user.node.firstName}`}
                    >
                        {`${user.node.lastName} ${user.node.firstName}`}
                    </Select.Option>
                ))
            }
        </Select>
        <Button
            type="primary"
            onClick={() => {
                updatePosting({
                    variables: {
                        input: {
                            id: posting.id,
                            status: "requested",
                            delivererId: deliverer,
                        },
                    },
                });
            }}
        >
            決定
        </Button>
    </>
    const handleFinishDelivery = (posting) => {
        if (posting.status === "requested" && (currentUser.id === parseInt(deliverer) || currentUser.id === parseInt(posting.postedBy.id))) {
            return <Button
                type="primary"
                onClick={() => {
                    updatePosting({
                        variables: {
                            input: {
                                id: posting.id,
                                status: "arranged",
                            },
                        },
                    });
                }}
            >
                手配完了
            </Button>
        }
    }
    const handleMemoInput = (posting) => <Input.TextArea
        placeholder="備考を入力してください。"
        minLength={10}
        style={{height: '5rem', borderTop: !isMobile() && 'none'}}
        value={memo}
        disabled={posting.status !== "draft"}
        onChange={(e) => setMemo(e.target.value)}
    />
    return <>
        {buttonMode ? <Button icon={<MessageOutlined/>} onClick={() => setVisible(true)}/> : <Button type='link' onClick={() => setVisible(true)}>リクエスト</Button>}
        <Drawer
            width={isMobile() ? '100vw' : 450}
            open={visible}
            onClose={() => setVisible(false)}
            style={{ zIndex: 1002 }}
            extra={
                <>
                    <Tag color='volcano'>ID {posting.id}</Tag>
                    {taggify(posting.status)}
                        {posting.status !== "archived" ? (
                            <Popconfirm
                                title="リクエストを削除しますか？"
                                okText="削除"
                                cancelText="キャンセル"
                                placement='bottomLeft'
                                onConfirm={() => {
                                    updatePosting({
                                        variables: {
                                            input: {
                                                id: posting.id,
                                                status: "archived",
                                            },
                                        },
                                    });
                                }}
                            >
                                <DeleteOutlined
                                    style={{
                                        marginRight: 5,
                                        fontSize: 16,
                                        zIndex: 1,
                                    }}
                                />
                            </Popconfirm>
                        ) : (
                            <Popconfirm
                                title="リクエストを復元しますか？"
                                okText="復元"
                                cancelText="キャンセル"
                                placement='bottomLeft'
                                onConfirm={() => {
                                    updatePosting({
                                        variables: {
                                            input: {
                                                id: posting.id,
                                                status: "available",
                                            },
                                        },
                                    });
                                }}
                            >
                                <EditOutlined
                                    style={{
                                        marginRight: 5,
                                        fontSize: 16,
                                        zIndex: 1,
                                    }}
                                />
                            </Popconfirm>
                        )}
                </>
            }
        >
            <CommentList 
                posting={posting}
                commenterIds={commenterIds}
                setSelectedCommenterId={setSelectedCommenterId}
                setOpenCommentDrawer={setOpenCommentDrawer}
                userListData={commenterUsers}
            />
            <CommentDrawer
                open={openCommentDrawer}
                setOpen={setOpenCommentDrawer}
                comments={comments}
                selectedCommenterId={selectedCommenterId}
                toUser={commenterUsers?.find(user => user?.id === selectedCommenterId)}
                postingId={posting.id}
                createComment={createTruckPostingComment}
                updateComment={updateTruckPostingComment}
                deleteComment={deleteTruckPostingComment}
            />

            {!isMobile() && <>
            
            {posting && columnList.map((label, index) => {
                return (
                    <Row key={index}>
                        <Col span={8} style={{ backgroundColor: '#FAFAFA', height: '3rem', paddingLeft: '1rem', borderBottom: '1px solid lightgray', borderLeft: '1px solid lightgray', borderTop: index === 0 && '1px solid lightgray', alignContent: 'center'}}>
                            {label}
                        </Col>
                        <Col span={16} style={{ height: '3rem', paddingLeft: '1rem', borderBottom: '1px solid lightgray', borderLeft: '1px solid lightgray', borderRight: '1px solid lightgray', borderTop: index === 0 && '1px solid lightgray', alignContent: 'center'}}>
                            {posting[labelNameMapping[label]]}
                        </Col>
                    </Row>
                )
            })}
            <div style={{width: "100%"}}>
                {
                    // If the posting is a draft and the user is the poster, show the memo input field.
                    posting.status === "draft" ? (
                        parseInt(posting.postedBy?.id) ===
                            parseInt(currentUser.id) && (
                            <Row>
                                <Col span={8} style={{ backgroundColor: '#FAFAFA', height: '5rem', paddingLeft: '1rem', borderBottom: '1px solid lightgray', borderLeft: '1px solid lightgray', alignContent: 'center'}}>
                                    備考
                                </Col>
                                <Col span={16} style={{ height: '5rem', alignContent: 'center'}}>
                                    {handleMemoInput(posting)}
                                </Col>
                            </Row>
                        )
                    ) : (
                        // if its not a draft, show the memo.
                        <Row>
                            <Col span={8} style={{ backgroundColor: '#FAFAFA', height: '5rem', paddingLeft: '1rem', borderBottom: '1px solid lightgray', borderLeft: '1px solid lightgray', alignContent: 'center'}}>
                                備考
                            </Col>
                            <Col span={16} style={{ height: '5rem', paddingLeft: '1rem', borderBottom: '1px solid lightgray', borderLeft: '1px solid lightgray', borderRight: '1px solid lightgray', alignContent: 'center', overflow: 'auto'}}>
                                {posting.memo?.length && <div dangerouslySetInnerHTML={{ __html: posting.memo.replace(/\n/g, '<br/>') }} />}
                            </Col>
                        </Row>
                    )
                }
                {posting.deliverer && (
                    <Row>
                        <Col span={8} style={{ backgroundColor: '#FAFAFA', height: '3rem', paddingLeft: '1rem', borderBottom: '1px solid lightgray', borderLeft: '1px solid lightgray', alignContent: 'center'}}>
                            配送者
                        </Col>
                        <Col span={16} style={{ height: '3rem', paddingLeft: '1rem', borderBottom: '1px solid lightgray', borderLeft: '1px solid lightgray', borderRight: '1px solid lightgray', alignContent: 'center'}}>
                            {`${posting.deliverer?.lastName} ${posting.deliverer?.firstName}`}
                        </Col>
                    </Row>
                )}
                {handlePostButton(posting)}
                {posting.status === "available" &&
                    // If the user is the poster, show the deliverer selection field
                    parseInt(posting.postedBy?.id) ===
                        parseInt(currentUser.id) && (
                        <Row>
                            <Col span={8} style={{ backgroundColor: '#FAFAFA', height: '3rem', paddingLeft: '1rem', borderBottom: '1px solid lightgray', borderLeft: '1px solid lightgray', alignContent: 'center'}}>
                                配送者
                            </Col>
                            <Col span={16} style={{ height: '3rem', paddingLeft: '1rem', borderBottom: '1px solid lightgray', borderLeft: '1px solid lightgray', borderRight: '1px solid lightgray', alignContent: 'center'}}>
                                {handleDriverSelect(posting)}
                            </Col>
                        </Row>
                    )}
                {handleFinishDelivery(posting)}
                <Divider />
                </div>
            </>}

            {isMobile() && <>
                {posting && columnList.map((label, index) => {
                    return (
                        <Row key={index} style={mobileRowStyle}>
                            <Typography.Text style={{ fontSize: '0.8rem', color: '#888'}}>{label}</Typography.Text>
                            <Typography.Text>{posting[labelNameMapping[label]]}</Typography.Text>
                        </Row>
                    )
                })}
                {
                    // If the posting is a draft and the user is the poster, show the memo input field.
                    posting.status === "draft" ? (
                        parseInt(posting.postedBy?.id) ===
                            parseInt(currentUser.id) && (
                            <Row style={mobileRowStyle}>
                                <Typography.Text style={{ fontSize: '0.8rem', color: '#888'}}>備考</Typography.Text>
                                {handleMemoInput(posting)}
                            </Row>
                        )
                    ) : (
                        // if its not a draft, show the memo.
                        <Row style={mobileRowStyle}>
                            <Typography.Text style={{ fontSize: '0.8rem', color: '#888'}}>備考</Typography.Text>
                            <Typography.Text >{posting.memo}</Typography.Text>
                        </Row>
                    )
                }
                {posting.deliverer && (
                    <Row style={mobileRowStyle}>
                        <Typography.Text style={{ fontSize: '0.8rem', color: '#888'}}>配送者</Typography.Text>
                        <Typography.Text>
                            {`${posting.deliverer?.lastName} ${posting.deliverer?.firstName}`}
                        </Typography.Text>
                    </Row>
                )}
                {handlePostButton(posting)}
                {posting.status === "available" &&
                    // If the user is the poster, show the deliverer selection field
                    parseInt(posting.postedBy?.id) ===
                        parseInt(currentUser.id) && (
                        <div style={{ marginTop: '1rem'}}>
                            {handleDriverSelect(posting)}
                        </div>
                )}
                {handleFinishDelivery(posting)}
            </>
            }
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: '1rem'}}>
                {traboxButton || null}
            </div>
        </Drawer>
    </>
};

export default DetailDrawer;
