import React, { useEffect, useState, useCallback } from 'react';
import { useQuery, useMutation, useApolloClient } from '@apollo/client';
import {
    Button,
    Card,
    Space,
    Typography,
    Table,
    Progress,
    message,
    Skeleton,
    Spin,
    Tour
} from 'antd';
import { WarningFilled, CheckCircleFilled, LoadingOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { debounce } from 'lodash'; // Import debounce from lodash
import { GET_STATIONS, GET_MANUAL_FLAG_PERCENTAGE, GET_LATEST_LABEL_MODEL, GET_LATEST_SUCCESS_MODELS } from '../../queries';
import { CREATE_LABEL_MODEL } from '../../mutations';
import { authenticationService } from '../../Utilities/authenticationService';
import momentify from '../../Utilities/momentify';

const LabelModel = () => {
    const client = useApolloClient();
    const currentUser = authenticationService.currentUserValue
    const { loading: loadingStations, data: stationsData } = useQuery(GET_STATIONS, {
        fetchPolicy: 'cache-and-network',
    });

    const { loading: loadingModels, data: latestSuccessModelsData } = useQuery(GET_LATEST_SUCCESS_MODELS, {
        fetchPolicy: 'network-only',
    });

    const { loading: loadingManual, data: manualLabelData, refetch } = useQuery(GET_MANUAL_FLAG_PERCENTAGE, {
        variables: { companyId: currentUser.company_id }, // Replace `yourCompanyId` with the actual company ID
        fetchPolicy: 'no-cache',
    });
    const [rows, setRows] = useState([]);
    const [createLabelModel] = useMutation(CREATE_LABEL_MODEL);
    const [loadingRow, setLoadingRow] = useState({});
    const [openTour, setOpenTour] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [processingStations, setProcessingStations] = useState(new Set());

    // Determine if a label model is available for a given station ID
    const isModelAvailableForStation = useCallback((stationId) => {
        console.log('Station ID being checked:', stationId, 'type:', typeof stationId);
        console.log('Available models data:', latestSuccessModelsData?.getLatestSuccessModels);
        
        const isAvailable = latestSuccessModelsData?.getLatestSuccessModels?.some(model => {
            return parseInt(model.stationId) === parseInt(stationId);
        }) || false;
        
        console.log('Is model available for station:', isAvailable);
        return isAvailable;
    }, [latestSuccessModelsData]);

    // Get the latestUpdate date for a given station ID
    const getLatestUpdateForStation = useCallback(
        (stationId) => {
            if (!latestSuccessModelsData?.getLatestSuccessModels) return null;

            // Find the model for this station
            const stationModel = latestSuccessModelsData.getLatestSuccessModels.find(
                model => parseInt(model.stationId) === parseInt(stationId)
            );
            
            return stationModel ? stationModel.latestUpdate : null;
        }, 
        [latestSuccessModelsData]
    );

    // Modify updateRows to preserve isModelAvailable state
    const updateRows = useCallback(
        debounce(() => {
            if (manualLabelData && manualLabelData.manualFlagPercentage) {
                setRows((prevRows) => {
                    return manualLabelData.manualFlagPercentage.map((manualPercent) => {
                        const { stationId, manualPercentage, manualCount } = manualPercent;
                        
                        // Preserve isModelAvailable state from previous rows
                        const existingRow = prevRows.find((row) => row.station === stationId);

                        return {
                            station: stationId,
                            progress: manualPercentage,
                            manualCount: manualCount,
                            latestUpdate: getLatestUpdateForStation(stationId),
                            isModelAvailable: existingRow ? existingRow.isModelAvailable : isModelAvailableForStation(stationId),
                        };
                    }).sort((a, b) => a.station - b.station);
                });
            } else {
                setRows([]);
            }
        }, 500), // Debounce to avoid unnecessary updates
        [manualLabelData, getLatestUpdateForStation, isModelAvailableForStation]
    );

    useEffect(() => {
        if (!loadingManual) {
            updateRows();
        }
    }, [loadingManual, updateRows]);

    const handleExecuteLabelModel = async (stationId, progress) => {
        // Check if this station is already being processed
        if (processingStations.has(stationId)) {
            console.log(`Station ${stationId} is already being processed, skipping request`);
            message.warning('この営業所のモデル作成は既に実行中です。完了までお待ちください。');
            return;
        }

        console.log(`Starting model creation for station ${stationId}`);
        setLoadingRow((prev) => ({ ...prev, [stationId]: true }));
        setProcessingStations(prev => new Set(prev).add(stationId));

        try {
            const response = await createLabelModel({
                variables: {
                    input: { stationId, progress },
                },
            });
            console.log(`Model creation response for station ${stationId}:`, response); 

            // Check if the mutation was successful and the model was created
            if (!response.data?.createLabelModel?.labelModel) {
                console.error(`Failed to create model for station ${stationId}: No response data`);
                message.error('モデル作成のリクエストに失敗しました。');
                setLoadingRow((prev) => ({ ...prev, [stationId]: false }));
                setProcessingStations(prev => {
                    const newSet = new Set(prev);
                    newSet.delete(stationId);
                    return newSet;
                });
                return;
            }

            const createdModel = response.data.createLabelModel.labelModel;
            console.log(`Model creation request sent for station ${stationId}`, {
                modelId: createdModel.id,
                status: createdModel.status
            });

            // Check initial status
            if (createdModel.status === 'failed') {
                console.error(`Model creation failed for station ${stationId}: ${response.data.createLabelModel.message}`);
                message.error('モデル作成のリクエストに失敗しました。');
                setLoadingRow((prev) => ({ ...prev, [stationId]: false }));
                setProcessingStations(prev => {
                    const newSet = new Set(prev);
                    newSet.delete(stationId);
                    return newSet;
                });
                return;
            }

            message.info('モデル作成を実行しました。処理が完了するまでしばらくお待ちください。');

            // Poll for status every 10 seconds
            const checkModelStatus = async () => {
                console.log(`Checking model status for station ${stationId}`);
                const { data } = await client.query({
                    query: GET_LATEST_LABEL_MODEL,
                    variables: { stationId },
                    fetchPolicy: 'network-only' // Don't use cache
                });

                const currentModel = data?.getLatestLabelModel;

                console.log(`Current model status for station ${stationId}:`, currentModel?.status);

                if (currentModel?.status === 'success') {
                    console.log(`Model creation completed successfully for station ${stationId}`);
                    message.success('モデル作成が完了しました。');
                    // Update UI only after success confirmation
                    setRows((prevRows) =>
                        prevRows.map((row) =>
                            row.station === stationId
                                ? { 
                                    ...row, 
                                    latestUpdate: currentModel.latestUpdate,
                                    isModelAvailable: true 
                                }
                                : row
                        )
                    );
                    setIsRefetching(true);
                    await refetch();
                    setIsRefetching(false);
                    return true;
                } else if (currentModel?.status === 'failed') {
                    console.log(`Model creation failed for station ${stationId}`);
                    message.error('モデル作成に失敗しました。');
                    return true;
                }
                return false;
            };

            // Poll every 10 seconds for up to 5 minutes
            let attempts = 30; // 5 minutes = 33 attempts * 10 seconds
            console.log(`Starting polling for station ${stationId}, will check ${attempts} times`);
            
            // Keep the loading state active during polling
            setLoadingRow((prev) => ({ ...prev, [stationId]: true }));
            
            const pollInterval = setInterval(async () => {
                attempts--;
                console.log(`Polling attempt ${30 - attempts} of 30 for station ${stationId}`);
                const finished = await checkModelStatus();
                if (finished || attempts <= 0) {
                    clearInterval(pollInterval);
                    if (attempts <= 0) {
                        console.log(`Polling timed out for station ${stationId}`);
                        message.warning('モデル作成の状態確認がタイムアウトしました。');
                    }
                    setLoadingRow((prev) => ({ ...prev, [stationId]: false }));
                    setProcessingStations(prev => {
                        const newSet = new Set(prev);
                        newSet.delete(stationId);
                        return newSet;
                    });
                }
            }, 10000);

        } catch (error) {
            console.error(`Error during model creation for station ${stationId}:`, error);
            message.error('モデル作成の実行に失敗しました。');
            setIsRefetching(false);
            setLoadingRow((prev) => ({ ...prev, [stationId]: false }));
            setProcessingStations(prev => {
                const newSet = new Set(prev);
                newSet.delete(stationId);
                return newSet;
            });
        }
    };

    const columns = [
        {
            title: <Typography.Text data-testid='table-col-station' strong>営業所</Typography.Text>,
            dataIndex: 'station',
            width: '20%',
            render: (station) => {
                const stationData = stationsData?.stations?.edges?.find(
                    (edge) => parseInt(edge.node.id) === parseInt(station)
                );
                return (
                    <Typography.Text>
                        {stationData ? stationData.node.officialName : '不明な営業所'}
                    </Typography.Text>
                );
            }
        },
        {
            title: <Typography.Text data-testid="table-col-status" strong>ラベルデータ</Typography.Text>,
            width: '50%',
            render: (record) => {
                const isHighProgress = record.progress >= 50;
        
                return (
                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px', maxWidth: '200px' }}>
                        {/* Progress Bar */}
                        <div style={{ flexGrow: 1 }}>
                            <Progress
                                percent={record.progress}
                                steps={20}
                                status="active"
                                showInfo={false} // Disable built-in percentage display
                            />
                        </div>
                        {/* Icon */}
                        {isHighProgress ? (
                            <CheckCircleFilled style={{ color: 'green', fontSize: '16px' }} />
                        ) : (
                            <WarningFilled style={{ color: 'red', fontSize: '16px' }} />
                        )}
                        {/* Percentage Display */}
                        <Typography.Text style={{ fontSize: '14px', whiteSpace: 'nowrap' }}>
                            {record.progress}%
                        </Typography.Text>
                    </div>
                );
            },
        },
        {
            title: <Typography.Text data-tetid='table-col-command' strong>操作</Typography.Text>,
            align: 'center',
            width: '15%',
            render: (record) => {
                const isHighPercentage = record.progress >= 50;
                const hasEnoughData = record.manualCount >= 50;
                const isLoading = loadingRow[record.station] || isRefetching;
                const isProcessing = processingStations.has(record.station);

                console.log('Record:', record);

                return (
                    <Space>
                        <Button
                            type={isHighPercentage && hasEnoughData ? 'primary' : 'default'}
                            style={{ borderRadius: "7px" }} // Rounded corners
                            onClick={() => {
                                if (isHighPercentage && hasEnoughData && !isLoading && !isProcessing) {
                                    try {
                                        message.success('モデル作成・更新中');
                                        // Asynchronous call
                                        handleExecuteLabelModel(record.station, record.progress);
                                    } catch (error) {
                                        message.error('モデルの作成に失敗しました');
                                        console.error(error);
                                    }
                                } else if (!isHighPercentage || !hasEnoughData) {
                                    if (!isHighPercentage && !hasEnoughData) {
                                        message.warning('ラベルデータが不足しています。50%以上のデータと最低50件のラベルデータが必要です');
                                    } else if (!isHighPercentage) {
                                        message.info('ラベルデータが不足しています。50%以上のデータが必要です');
                                    } else if (!hasEnoughData) {
                                        message.info('ラベルデータが不足しています。最低50件のラベルデータが必要です');
                                    }
                                }
                            }}
                            disabled={isLoading || isProcessing}
                        >
                            {isLoading || isProcessing ? (
                                <Spin indicator={<LoadingOutlined style={{ fontSize: 16, color: '#1890ff' }} spin />} />
                            ) : (
                                !!isModelAvailableForStation(record.station) ? '更新' : '作成'
                            )}
                        </Button>
                    </Space>
                )
            }
        },
        {
            title: <Typography.Text data-testid="table-col-updated" strong>更新日</Typography.Text>,
            dataIndex: "latestUpdate",
            width: '15%',
            render: (updatedAt, record) => (
                <Typography.Text>
                    {record.isModelAvailable && updatedAt
                        ? momentify(updatedAt) // Display the formatted date if the model is available
                        : "モデル未作成"}
                </Typography.Text>
            ),
        }
    ];
    const steps = [
        {
            title: 'STEP 1',
            description: 'ラベルデータが50％以上、最低50件のラベルデータが必要です。ラベルツールを確認して過去1ヶ月分のラベル付けをしてください。',
            nextButtonProps: {
                type: 'primary',
                children: '次へ'
            }
        },
        {
            title: 'STEP 2',
            description: 'ラベルの更新が完了したら、作成または更新ボタンを押して、モデルを作成してください。',
            nextButtonProps: {
                type: 'primary',
                children: '次へ'
            },
            prevButtonProps: {
                type: 'default',
                children: '前へ'
            }
        },
        {
            title: 'STEP 3',
            description: 'モデル作成が完了したら、ラベルツールで自動ラベルを作成することができるようになります。',
            nextButtonProps: {
                type: 'primary',
                children: '完了'
            },
            prevButtonProps: {
                type: 'default',
                children: '前へ'
            }
        }
    ]

    if (loadingStations || loadingModels || loadingManual) {
        return <Skeleton active />;
    }

    return (
        <Space direction='vertical' style={{ width: '100%' }}>
            <Card bordered={false} style={{padding: '0px 24px 0px 24px'  }}>
            <Button
                icon={<QuestionCircleOutlined style={{ fontSize: '20px' }} />}
                onClick={() => {
                    setOpenTour(true)
                }}
                variant='text'
                style={{ border: 'none', fontSize: '20px' }}
            />
                <Table
                    columns={columns}
                    dataSource={rows}
                    pagination={false}
                    bordered
                />
            </Card>
            <Tour 
                open={openTour} 
                onClose={() => setOpenTour(false)} 
                steps={steps}
            />
        </Space>
    );
};

export default LabelModel