import React, { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { Button, message, Typography, Form, Input, Row, Space, Checkbox, Select } from 'antd';
import { UPDATE_STATION } from '../../mutations';
import propTypes from 'prop-types';
import { PREFECTURES_TO_REGIONS } from '../../constants';

const EditStationModal = ({ handleCompleted, returnToTable, station }) => {
    const [updateStation] = useMutation(UPDATE_STATION, {
        onCompleted: (data) => {
            handleCompleted(data.updateStation.station);
        },
        onError: () => {
            message.error('営業所の更新に失敗しました。');
        },
        refetchQueries: ['getAllStations']
    });

    const onFinish = (values) => {
        updateStation({ 
            variables: { 
                input: { 
                    id: station.key,
                    ...values
                } 
            } 
        });
    };

    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue({
            stationName: station.stationName,
            officialName: station.officialName,
            isOpen: station.isOpen,
            longtitude: station.longtitude,
            latitude: station.latitude,
            prefecture: station.prefecture
        });
    }, [station, form]);

    const tailFormItemLayout = {
        wrapperCol: {
            sm: {
                span: 16,
                offset: 9,
            }
        }
    };

    return <Form
        labelCol={ { span: 4 } }
        wrapperCol={ { span: 8 } }
        name='editStation'
        form={form}
        style={{
            maxWidth: 600,
        }}
        onFinish={onFinish}
    >
        <Form.Item>
            <Typography.Title level={5}>営業所編集</Typography.Title>
        </Form.Item>
        <Form.Item
            label="営業所"
            name='stationName'
            rules={[{ required: true, message: '営業所名を入力してください。' }]}
        >
            <Input />
        </Form.Item>
        <Form.Item
            label="営業所(official)"
            name='officialName'
        >
            <Input />
        </Form.Item>
        <Form.Item
            label="営業中"
            name='isOpen'
            valuePropName='checked'
        >
            <Checkbox />
        </Form.Item>
        <Form.Item
            label="経度"
            name='longtitude'
        >
            <Input />
        </Form.Item>
        <Form.Item
            name='latitude'
            label="緯度"
        >
            <Input />
        </Form.Item>
        <Form.Item
            name="prefecture"
            label="都道府県"
            rules={[{ required: true, message: '都道府県を入力してください。' }]}
        >
            <Select
                options={Object.keys(PREFECTURES_TO_REGIONS).map(prefecture => ({ label: prefecture, value: prefecture }))}
            />
        </Form.Item>
        <Row>
            <Space>
                <Form.Item shouldUpdate {...tailFormItemLayout}>
                    {() => (
                        <Button
                            type="primary"
                            htmlType='submit'
                        >
                            更新する
                        </Button>
                    )}
                </Form.Item>
                <Form.Item {...tailFormItemLayout}>
                    <Button onClick={returnToTable}>
                        キャンセル
                    </Button>
                </Form.Item>
            </Space>
        </Row>
    </Form>;
};

EditStationModal.propTypes = {
    handleCompleted: propTypes.func,
    returnToTable: propTypes.func,
    station: propTypes.object
};

export default EditStationModal; 